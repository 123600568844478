// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import jquery from "jquery"
window.$ = window.jquery = jquery
import 'mediaelement/full'
import "/tmp/build_fb9770ce/vendor/bundle/ruby/2.6.0/gems/rails-social-share-button-0.0.2/app/assets/javascripts/rails-social-share-button.js";
require("jquery")
global.toastr = require("toastr")
require("selectize")
require("js-marker-clusterer/src/markerclusterer.js")
require("jquery-datetimepicker/build/jquery.datetimepicker.full.min.js")
const { detect } = require('detect-browser');
import LocalTime from "local-time"
LocalTime.start()

window.detect_browser = detect();

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(function () {
  var remoteLinks = document.querySelectorAll('a[data-remote=\'true\']');

  for (var i = 0; i < remoteLinks.length; i++) {
    var link = remoteLinks[i];
    link.dataset.url = link.href;
    link.setAttribute('href', 'javascript:void(0);');
  }

  $.rails.href = function(element) {
    if (element.dataset) {
      var url = element.dataset.url || element.dataset.href;
      if (url) { return url; }
    }
    return element.href;
  }

  bindAjaxSuccess();

  mediaPlayer();
  replaceHtmlToLink();
});

function bindAjaxSuccess() {
  setTimeout(function(){
    mediaPlayer();
    replaceHtmlToLink();

    $("a[data-remote='true']").on("ajax:success", function() {
      bindAjaxSuccess();
    });
  }, 1000);
}

function replaceHtmlToLink() {
  $(".replace-html-to-link").each(function(e) {
    var str = $(this).html();

     var regex = /(^|[\s.:;?\-\]<\(])((https?|www\.|pic\.)[-\w;\/?:@&=+$\|\_.!~*\|'()\[\]%#,☺]+[\w\/#](\(\))?)(?=$|[\s',\|\(\).:;?\-\[\]>\)])/m;
 
    // REPLACE PLAIN TEXT LINKS BY HYPERLINKS
    var replaced_text = str.replace(regex, " <a href='$2' class='js-link' target='_blank'>$2</a>");
 
    jQuery(this).html(replaced_text);
  })

  // DEFINE URLS WITHOUT "http" OR "https"
  var linkHasNoHttp = $(".js-link:not([href*=http],[href*=https])");
      
  // ADD "http://" TO "href" 
  $(linkHasNoHttp).each(function() {
    var linkHref = $(this).attr("href");  
    $(this).attr("href" , "http://" + linkHref);
  });
}

function mediaPlayer() {
  $('audio, video').mediaelementplayer({
    // When using jQuery's `mediaelementplayer`, an `instance` argument
    // is available in the `success` callback
    features: ["playpause", "progress", "current", "duration", "tracks", "volume"],
    success: function(mediaElement, originalNode, instance) {
      // do things
    }
  });  
}
